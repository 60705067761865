jQuery(document).ready(function($) {


    /*======= Skillset *=======*/
    
    $('.level-bar-inner').css('width', '0');
    
    $(window).on('load', function() {

        $('.level-bar-inner').each(function() {
        
            var itemWidth = $(this).data('level');
            
            $(this).animate({
                width: itemWidth
            }, 800);
            
        });

    });
    
    /* Bootstrap Tooltip for Skillset */
    $('.level-label').tooltip();

   // $('.summernote').summernote();

    $('.summernote').summernote({
        height: 300,
        toolbar: [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['fontsize', ['fontsize', 'height']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['html', ['table', 'hr', 'link', 'picture', 'video']],
            ['props', ['fullscreen', 'codeview', 'undo', 'redo']]
        ]
    });


});